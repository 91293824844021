/*--------------------------------------------------------------
# Accordian
--------------------------------------------------------------*/

.accordian-tab-menu{
    .nav-link{
        background:$aquablue-color;
        color:$black!important;
        border:1px solid #bed2f0;
        border-radius: 0px;
        margin:5px 10px;
        padding:8px 30px;        
        font-size:14px;
        font-weight:700;
        text-transform:uppercase;
        @media(max-width:767px){
            font-size:10px;
            padding: 5px 8px;            
        }  
        @media(max-width: 414px){
           margin:2px 0px; 
        }      
    }
    .nav-link.active{
        @extend .d3-gradient-bg;
        color:$white!important;
    }

}


  .accordion > dt {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
    }
    .accordion > dt > a {
        display: block;
        position: relative;
        color: #222222;
        text-decoration: none;
        padding: 9px 30px;
        font-size:16px;
        font-weight:500;
        border-bottom:1px solid $aquablue-color;
        @media(max-width:414px){
            font-size:12px;
        }
    }
    .accordion > dt > a:hover {
        text-decoration: none;
    }
    .accordion > dt > a.active {
        color: $primary-color2;
        border-color: #ddd;
        cursor: default;
        border-bottom:1px solid $primary-color2;
        background:transparent;
    }

    .accordion > dt > a:after {
        content: "\e873";
        width: 15px;
        height: 15px;
        margin-top: -8px;
        position: absolute;
        top: 50%;
        left: 10px;
        font-family: 'Linearicons-Free';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: 15px;
        line-height: 15px;
        text-align: center;
        color: $black;
        -webkit-font-smoothing: antialiased;
    }
    .accordion > dt > a.active:after,
    .accordion > dt > a.active:hover:after {
        content: "\e874";
        color: $primary-color2;
    }
    .accordion > dt > a:hover:after {
        color: #444;
    }
    .accordion > dd {
        margin-bottom: 10px;
        padding: 10px 20px 20px;
        font-size: 14px;
        line-height: 1.8;
        color: #777;
    }
    .accordion > dt > a,
    .accordion > dt > a:after,
    .toggle > dt > a,
    .toggle > dt > a:after {
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }
    dl.accordion.time-line {
        position: relative;
    }
    dl.accordion.time-line:before {
        border-left: 1px dashed #cecece;
        bottom: 10px;
        content: "";
        left: 15px;
        position: absolute;
        top: 25px;
        z-index: 0;
    }
    .accordion.time-line > dd {
        margin-bottom: 10px;
        padding: 10px 20px 20px 40px;
        font-size: 14px;
        line-height: 1.8;
        color: #777;
    }
    .accordion.time-line > dt > a:after {
        content: "\e648";
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        left: 0;
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: bold;
        font-variant: normal;
        text-transform: none;
        font-size: 20px;
        text-align: center;
        color: #fff;
        -webkit-font-smoothing: antialiased;
        background: #222;
        padding-left: 3px;
    }
    .accordion.time-line > dt > a.active:after,
    .accordion.time-line > dt > a.active:hover:after {
        content: "\e641";
        color: #fff;
    }
    .accordion.time-line > dt > a {
        display: block;
        position: relative;
        color: #222222;
        text-decoration: none;
        padding: 14px 20px 14px 40px;
        border: none;
        letter-spacing: 3px;
    }
    .accordion.time-line > dt > a:hover,
    .accordion.time-line > dt > a.active {
        background: none !important;
    }



