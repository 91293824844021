$fa-font-path: "../webfonts";

@import "./assets/scss/main";
@import "./assets/scss/bootstrap";


@import url('https://cdn.linearicons.com/free/1.0.0/icon-font.min.css');


//@import "./assets/css/nice-select.css";

@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import '~react-modal-video/scss/modal-video.scss';

@import '~react-toastify/scss/main.scss';

//@import "./assets/css/animate.min.css";

