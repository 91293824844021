
//    Font Family

$primary-font	: 'Montserrat', sans-serif;

// --------------------------- Color variations ----------------------

$primary-color	: #7b10ff;
$primary-color2	: #974fff;
$title-color	: #1e283c;
$text-color		: #50668f;
$aquablue-color : #edf6ff;

$white			: #fff;
$offwhite		: #f2f5fa;
$black			: #222;