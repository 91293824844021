/*--------------------------------------------------------------
# Hero
--------------------------------------------------------------*/

.version1 {
    .hero-section {
        background: url(../img/hero/hero-bg.png) no-repeat left center/cover;
    }
}

.version1-1 {
    .hero-section {
        background: url(../img/hero/hero-bg1-1.png) no-repeat left center/cover;
        .content-wrap {
            h1 {
                color: $white;
                @media (max-width: 991px) {
                    font-size: 30px !important;
                }
            }
            p {
                color: $white;
            }
            @media (max-width: 767px) {
                margin-bottom: 50px;
            }
        }
    }
}

.version2 {
    .hero-section {
        background: url(../img/hero/hero-bg2.png) no-repeat bottom center/cover;
    }
    .content-wrap {
        h1 {
            color: $black;
            @media (max-width: 991px) {
                font-size: 30px !important;
            }
        }
        p {
            color: $black;
        }
        @media (max-width: 767px) {
            margin-bottom: 50px;
        }
    }
}

.version3 {
    .hero-section {
        background: url(../img/hero/hero-bg2.png) no-repeat bottom center/cover;
    }
    .content-wrap {
        h1 {
            color: $white;
            @media (max-width: 991px) {
                font-size: 30px !important;
            }
        }
        p {
            color: $white;
            @media (max-width: 991px) {
                color: $white !important;
            }
        }
        @media (max-width: 767px) {
            margin-bottom: 50px;
        }
    }
}

.version3 {
    .hero-section {
        background: url(../img/hero/hero-bg3.png) no-repeat bottom center/cover;
        background-size: 100% 100%;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            @extend .v3-gradient-bg;
            opacity: 0.6;
        }
    }
}

.version4 {
    .hero-section {
        background: url(../img/hero/hero-bg4.png) no-repeat bottom center/cover;
        background-size: 100% 100%;
        .content-wrap {
            h1 {
                color: $white;
                @media (max-width: 991px) {
                    font-size: 30px !important;
                }
            }
            p {
                color: $white;
            }
            @media (max-width: 767px) {
                margin-bottom: 50px;
            }
        }
    }
}

.version7 {
    .hero-section {
        background: url(../img/hero/hero-bg7.png) no-repeat bottom center/cover;
        background-size: 100% 100%;
        .content-wrap {
            h1 {
                color: $black;
                @media (max-width: 991px) {
                    font-size: 30px !important;
                }
            }
            p {
                color: $black;
            }
            @media (max-width: 767px) {
                margin-bottom: 50px;
            }
        }
    }
}

.version8 {
    .hero-section-bg {
        .vdo-section {
            height: 100vh;
            @media (max-width: 767px) {
                height: 700px !important;
            }
            .hero-vdo {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
        #app-owl {
            .items {
                position: relative;
                .owl-img {
                    height: 100vh;
                    filter: brightness(60%);
                    -webkit-filter: brightness(60%);
                    -moz-filter: brightness(60%);
                    -ms-filter: brightness(60%);
                    @media (max-width: 991px) {
                        width: auto !important;
                    }
                }
            }
            .owl-controls {
                .owl-dots {
                    position: absolute;
                    bottom: 50px;
                    left: 46%;
                    .owl-dot {
                        background: #6e737f;
                        width: 8px;
                        height: 8px;
                        display: inline-block;
                        margin-right: 4px;
                        margin-left: 4px;
                        border-radius: 8px;
                    }
                    .owl-dot.active {
                        background: #ffffff;
                        width: 42px;
                        height: 8px;
                        margin-right: 2px;
                        border-radius: 11px;
                    }
                }
            }
        }
        .content {
            position: absolute;
            bottom: 13%;
            left: 15%;
            z-index: 10;
            @media (max-width: 1480px) {
                left: 3%;
            }
            @media (max-width: 10240px) {
                top: 27%;
            }
            @media (max-width: 767px) {
                padding: 0 24px;
                bottom: 28%;
            }
            .hero-img8 {
                @media (max-width: 991px) {
                    display: none;
                }
                @media (max-width: 1480px) {
                    left: 15px;
                }
                img {
                    @media (max-width: 1480px) {
                        width: 100%;
                    }
                }
            }
            h1 {
                color: $black;
                @media (max-width: 991px) {
                    font-size: 30px !important;
                }
            }
            p {
                color: $black;
            }
            @media (max-width: 767px) {
                margin-bottom: 50px;
            }
        }
    }
}
.opacidad {
    
    overflow: hidden;
    object-fit: cover !important;
   
}
.version9 {
    .hero-section-bg {
        .vdo-section {
            height: 100vh;
            @media (max-width: 767px) {
                height: 700px !important;
            }
            .hero-vdo {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
        #sass-owl {
            .items {
                .owl-img9 {
                    height: 100vh;

                    @media (max-width: 991px) {
                        width: auto !important;
                    }
                }
            }
            .owl-item {
                .items {
                    position: relative;
                    &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: none;
                        color: #fff;
                    }
                }
            }
            .owl-controls {
                .owl-dots {
                    position: absolute;
                    bottom: 28px;
                    left: 46%;
                    .owl-dot {
                        background: #6e737f;
                        width: 8px;
                        height: 8px;
                        display: inline-block;
                        margin-right: 4px;
                        margin-left: 4px;
                        border-radius: 8px;
                    }
                    .owl-dot.active {
                        background: #ffffff;
                        width: 42px;
                        height: 8px;
                        margin-right: 2px;
                        border-radius: 11px;
                    }
                }
            }
        }
        .content {
            position: absolute;
            top: 16%;
            width: 100%;
            z-index: 10;
            @media (max-width: 767px) {
                padding: 0 24px;
                top: 28%;
            }
            .hero-img8 {
                @media (max-width: 991px) {
                    display: none;
                }
            }
            h1 {
                color: $white;
                @media (max-width: 991px) {
                    font-size: 30px !important;
                }
            }
            p {
                color: $white;
            }
            @media (max-width: 767px) {
                margin-bottom: 50px;
            }
        }
    }
}

.version-blog {
    @media (max-width: 991px) {
        .owl-controls {
            display: none;
        }
    }
    .hero-section-blog {
        position: relative;
        .content {
            position: absolute;
            bottom: 0px;
            right: 0px;
            background: $offwhite;
            width: 100%;
            padding: 35px 40px;
            @media (max-width: 575px) {
                padding: 35px 15px;
            }
            .genric-btn3 {
                position: absolute;
                top: -50px;
                left: 0px;
            }
            h1 {
                font-size: 36px;
                font-weight: 700;
                @media (max-width: 991px) {
                    font-size: 28px;
                }
                br {
                    @media (max-width: 991px) {
                        display: none;
                    }
                }
            }
            .author {
                font-size: 12px;
                text-transform: uppercase;
            }
            .blog-summary {
                .blog-link {
                    margin-left: 24px;
                    color: $text-color;
                    font-size: 12px;
                    text-transform: uppercase;
                    @include transition();
                    &:hover {
                        color: $title-color;
                    }
                }
            }
            .blog-icon {
                @extend .d1-gradient-bg;
                color: $white;
                padding: 19px 22px;
                font-size: 18px;
            }
        }
    }
    .items {
        .owl-img {
            filter: brightness(75%);
            -webkit-filter: brightness(75%);
            -moz-filter: brightness(75%);
            @media (max-width: 991px) {
                height: 400px;
            }
        }
    }
    #blog-owl {
        .owl-nav {
            position: absolute;
            top: 50%;
            right: 0%;
            display: flex;
            width: 100%;
            justify-content: space-between;
            @media (max-width: 991px) {
                top: 560px;
                color: $white;
            }
            .owl-prev,
            .owl-next {
                @include transform(rotate(-90deg));
                i {
                    color: $white;
                    padding: 17px 18px;
                    background: rgba($white, 0.2);
                    @include transition();
                    &:hover {
                        cursor: pointer;
                        @extend .d1-gradient-bg;
                    }
                }
            }
        }
    }
    .hero-section-blog-details {
        .blog-img {
            width: 100%;
            filter: brightness(50%);
            -webkit-filter: brightness(50%);
            -moz-filter: brightness(50%);
            @media (max-width: 991px) {
                height: 400px;
            }
        }
        .content {
            width: 100%;
            position: absolute;
            bottom: 50px;
            @media (max-width: 991px) {
                bottom: 10%;
            }
            .content-wrap {
                @media (max-width: 991px) {
                    display: block;
                }
                p,
                div {
                    font-size: 12px;
                    text-transform: uppercase;
                }
                .content-wrap-child {
                    @media (max-width: 991px) {
                        display: block !important;
                    }
                }
                .text {
                    margin-right: 50px;
                    @media (max-width: 991px) {
                        margin-right: 0px;
                        text-align: center;
                        margin-bottom: 30px;
                    }
                }
                .icons {
                    @media (max-width: 991px) {
                        display: block !important;
                        text-align: center;
                    }
                    .icon {
                        @media (max-width: 991px) {
                            display: inline-block;
                        }
                        a {
                            color: $white;
                        }
                    }
                }
            }
            .social-link {
                margin: 0 10px;
                font-size: 14px;
                i {
                    @include transition(all 0.3s ease 0s);
                    color: $white;
                }
            }
            .social-link:hover {
                i {
                    color: $primary-color;
                }
            }
        }
    }
}

.content-wrap {
    h1 {
        @media (max-width: 991px) {
            font-size: 30px !important;
        }
        @media (max-width: 767px) {
            margin-bottom: 50px;
            margin-top: 50px;
        }
    }
}

.hero-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    @media (max-width: 992px) {
        display: none;
    }
}

.hero-img4 {
    position: absolute;
    right: 10px;
    top: -225px;
    width: 64%;
    @media (max-width: 1440px) {
        width: 48%;
    }
    @media (max-width: 992px) {
        display: none;
    }
}

.hero-img8 {
    position: relative;
    right: -17px;
    @media (max-width: 992px) {
        display: none;
    }
}

.hero-img9 {
    position: relative;
    left: 200px;
    height: 80%;
    width: 64%;
    top: 105px;
    @media (max-width: 1440px) {
        width: 48%;
        top: 20px;
    }
    @media (max-width: 992px) {
        display: none;
    }
}

.hero-section {
    @media (max-width: 767px) {
        padding: 120px 0;
        .fullscreen {
            height: auto !important;
        }
    }
    .content-wrap {
        z-index: 2;
        h1 {
            font-size: 60px;
            font-weight: 700;
            @media (max-width: 1199px) and(min-width: 992px) {
                font-size: 30px;
            }
            @media (max-width: 414px) {
                font-size: 35px;
            }
        }
        p {
            padding: 12px 0px 15px;
            max-width: 510px;
            @media (max-width: 991px) {
                color: $black;
            }
        }
    }
}

.hero-img-wrap {
    position: relative;
}

.hero-img {
    position: absolute;
    right: 0;
    bottom: 0px;
    width: 50%;
    @media (max-width: 736px) {
        display: none;
    }
}
